<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="filterData.keywords"
              :disabled="dataLoading"
              placeholder="Arama yapın.."
              @keydown.enter="callBack"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="dataLoading"
                @click="callBack"
              >
                <FeatherIcon
                  v-if="!dataLoading"
                  icon="SearchIcon"
                />
                <b-spinner
                  v-if="dataLoading"
                  small
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
        class="pt-1"
      >
        <b-form-checkbox
          v-model="filterData.detailSearch"
          switch
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <b-col
        md="auto"
        class="pt-1"
      >
        <b-button
          variant="primary"
          :to="'/app/stocks/add/'"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
      <template v-if="filterData.detailSearch">
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Stok Grubu"
            label-for="id_stock_groups"
          >
            <v-select
              id="id_stock_groups"
              v-model="filterData.id_stock_groups"
              :disabled="dataLoading"
              :options="stockGroups"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Ekleyen Kullanıcı"
            label-for="id_users"
          >
            <v-select
              id="id_users"
              v-model="filterData.id_users"
              :disabled="dataLoading"
              :options="users"
              :reduce="item => item.id"
              label="name"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center mb-2"
        >
          <b-button
            variant="primary"
            :disabled="dataLoading"
            @click="callBack"
          >
            <FeatherIcon
              v-if="!dataLoading"
              icon="FilterIcon"
            />
            <b-spinner
              v-if="dataLoading"
              small
            />
            Detaylı Arama
          </b-button>
        </b-col>
      </template>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'StockListFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    vSelect,
    BSpinner,
  },
  props: {
    callBack: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataLoading() {
      return this.$store.getters['stocks/dataLoading']
    },
    filterData() {
      return this.$store.getters['stocks/filterData']
    },
    stockGroups() {
      return this.$store.getters['stockGroups/dataList']
    },
    users() {
      return this.$store.getters['users/dataList']
    },
  },
  created() {
    this.setStockGroups()
    this.setUsers()
  },
  methods: {
    setStockGroups() {
      this.$store.dispatch('stockGroups/getDataList', {
        select: [
          'stock_groups.id AS id',
          'stock_groups.title AS title',
        ],
      })
    },
    setUsers() {
      this.$store.dispatch('users/getDataList', {
        select: [
          'users.id AS id',
          'users.name AS name',
        ],
        where: {
          'users.status': 1,
        },
      })
    },
  },
}
</script>
